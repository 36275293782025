import { useState } from "react";
import axiosInstance from "./UTILS/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faLock,
  faMailBulk,
  faPerson,
  faPhoneAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);
  const sweetAlert = withReactContent(Swal);
  const [formData, setFormData] = useState({
    userid: localStorage.getItem("userId"),
    email: localStorage.getItem("email"),
    name: localStorage.getItem("name"),
    phone: localStorage.getItem("phone"),
    password: "",
    confirmPassword: "",
  });
  const [requestPending, setRequestPending] = useState(false);

  const [passwordError, setPasswordError] = useState("");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }
    setRequestPending(true);
    axiosInstance
      .post("users/set", formData, { timeout: 15000 })
      .then((response) => {
        setRequestPending(false);
        if (response.data.status === "success") {
          localStorage.setItem("userId", formData.userid);
          localStorage.setItem("email", formData.email);
          localStorage.setItem("name", formData.name);
          localStorage.setItem("phone", formData.phone);

          sweetAlert.fire({ text: "Update successfully", icon: "success" });
        }
      })
      .catch((error) => {
        setRequestPending(false);
      });
  };

  return (
    <>
      <div className="content-container">
        <div className="content-module">
          <div
            className="content-list-body tab-content"
            id="TabContent"
            style={{ overflow: "auto", height: "calc(100vh - 50px)" }}
          >
            <form
              className="tab-pane fade"
              onSubmit={handleSubmit}
              id="notification"
              role="tabpanel"
              aria-labelledby="notifications-tab"
            ></form>
            <form
              className="tab-pane fade show active"
              onSubmit={handleSubmit}
              id="account"
              role="tabpanel"
              aria-labelledby="account-tab"
            >
              <div className="form-group mb-2">
                <label>
                  <FontAwesomeIcon className="icon-light" icon={faUser} />
                  Full Name:
                </label>

                <input
                  type="text"
                  placeholder="Full name"
                  name="name"
                  onChange={handleOnChange}
                  className="form-control"
                  value={formData.name}
                  required=""
                />
              </div>
              <div className="form-group mb-2">
                <label>
                  <FontAwesomeIcon className="icon-light" icon={faPhoneAlt} />
                  Phone number:
                </label>

                <input
                  onChange={handleOnChange}
                  type="text"
                  placeholder="Enter phone"
                  value={formData.phone}
                  name="phone"
                  className="form-control"
                />
              </div>
              <div className="form-group mb-2">
                <label>
                  <FontAwesomeIcon className="icon-light" icon={faMailBulk} />
                  Email:
                </label>

                <input
                  onChange={handleOnChange}
                  type="email"
                  placeholder="Enter your email address"
                  name="email"
                  value={formData.email}
                  className="form-control"
                  required=""
                />
              </div>

              <div className="form-group mb-2">
                <label>
                  <FontAwesomeIcon className="icon-light" icon={faLock} />
                  Password:
                </label>

                <input
                  type="password"
                  placeholder="Enter a new password"
                  name="password"
                  onChange={handleOnChange}
                  value={formData.password}
                  className="form-control"
                  autoComplete="new-password"
                />
                <small>Password must be at least 4 characters long</small>
              </div>
              <div className="form-group mb-2">
                <label>
                  <FontAwesomeIcon className="icon-light" icon={faLock} />
                  Confirm Password:
                </label>

                <input
                  type="password"
                  placeholder="Confirm your new password"
                  name="confirmPassword"
                  onChange={handleOnChange}
                  value={formData.confirmPassword}
                  className="form-control"
                  autoComplete="new-password"
                />
              </div>

              {passwordError && (
                <div className="alert alert-danger">{passwordError}</div>
              )}

              <div className="d-flex justify-content-end">
                {requestPending ? (
                  <button
                    type="submit"
                    className="btn btn-primary d-block ml-auto"
                    disabled
                  >
                    Please wait...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary d-block ml-auto"
                  >
                    Save
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="sidebar collapse" id="sidebar-collapse">
          <div className="sidebar-content">
            <div className="content-end-sidebar text-small">
              <div className="content-end-sidebar-top">
                <ul
                  className="nav nav-tabs flex-column px-2 text-uppercase"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#account"
                      role="tab"
                    >
                      <FontAwesomeIcon icon={faUser} />
                      Account
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#notification"
                      role="tab"
                    >
                      <FontAwesomeIcon icon={faBell} />
                      Notifications
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
