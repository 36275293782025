import { useEffect, useState } from "react";
import axiosInstance from "./UTILS/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faMailBulk,
  faPerson,
  faPhoneAlt,
  faUser,
  faUserCog,
} from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import base64Decode from "./UTILS/Base64Decoder";
import { useLocation, useNavigate } from "react-router-dom";

const SetUser = () => {
  const navigate = useNavigate();
  const sweetAlert = withReactContent(Swal);
  const location = useLocation();
  const [formData, setFormData] = useState({
    userid: "",
    email: "",
    username: "",
    phone: "",
    department: "",
    role: "",
    password: "",
    confirmPassword: "",
  });
  const [requestPending, setRequestPending] = useState(false);

  const [passwordError, setPasswordError] = useState("");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setRequestPending(true);
    axiosInstance
      .post("users/set", formData, { timeout: 15000 })
      .then((response) => {
        setRequestPending(false);
        if (response.data.status === "error") {
          sweetAlert.fire({ message: response.data.message, icon: "error" });
        } else {
          navigate("/users");
        }
      })
      .catch((error) => {
        setRequestPending(false);
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const param = queryParams.get("user");

    // Decode and parse the JSON string if it exists
    if (param) {
      const decode = base64Decode(param);

      if (!decode.error) {
        setFormData(decode.decodedData);
      }
    }
  }, []);

  return (
    <>
      <div className="content-container">
        <div className="content-module">
          <div style={{ overflow: "auto", height: "calc(100vh - 50px)" }}>
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <label>
                  <FontAwesomeIcon className="icon-light" icon={faUser} />
                  Full Name:
                </label>

                <input
                  type="text"
                  placeholder="Full name"
                  name="username"
                  onChange={handleOnChange}
                  className="form-control"
                  value={formData.username}
                  required=""
                />
              </div>
              <div className="form-group mb-3">
                <label>
                  <FontAwesomeIcon className="icon-light" icon={faPhoneAlt} />
                  Phone number:
                </label>

                <input
                  onChange={handleOnChange}
                  type="text"
                  placeholder="Enter phone"
                  value={formData.phone}
                  name="phone"
                  className="form-control"
                />
              </div>
              <div className="form-group  mb-3">
                <label>
                  <FontAwesomeIcon className="icon-light" icon={faMailBulk} />
                  Email:
                </label>

                <input
                  onChange={handleOnChange}
                  type="email"
                  placeholder="Enter your email address"
                  name="email"
                  value={formData.email}
                  className="form-control"
                  required=""
                />
              </div>

              <div className="form-group  mb-3">
                <label>
                  <FontAwesomeIcon className="icon-light" icon={faMailBulk} />
                  Department:
                </label>

                <input
                  onChange={handleOnChange}
                  type="text"
                  placeholder="Enter department"
                  name="department"
                  value={formData.department}
                  className="form-control"
                  required=""
                />
              </div>

              <div className="form-group  mb-3">
                <label>
                  <FontAwesomeIcon className="icon-light" icon={faMailBulk} />
                  Role:
                </label>

                <select
                  className="form-control"
                  name="role"
                  onChange={handleOnChange}
                  value={formData.role}
                  required
                >
                  <option value={""}>__select role__</option>
                  <option value={"Administrator"}>Administrator</option>
                  <option value={"priviledged"}>Priviledged</option>
                  <option value={"user"}>Normal user</option>
                </select>
              </div>

              <div className="form-group ">
                <label>
                  <FontAwesomeIcon className="icon-light" icon={faLock} />
                  Password:
                </label>

                <input
                  type="password"
                  placeholder="Enter a new password"
                  name="password"
                  onChange={handleOnChange}
                  className="form-control"
                  autoComplete="new-password"
                />
                <small>Password must be at least 4 characters long</small>
              </div>

              <div className="d-flex justify-content-end">
                {requestPending ? (
                  <button
                    type="submit"
                    className="btn btn-primary d-block ml-auto"
                    disabled
                  >
                    Please wait...
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary d-block ml-auto"
                  >
                    Save
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="sidebar collapse" id="sidebar-collapse">
          <div className="sidebar-content">
            <div className="content-end-sidebar text-small">
              <div className="content-end-sidebar-top text-center">
                <FontAwesomeIcon
                  style={{ fontSize: "40px" }}
                  className="icon-light"
                  icon={faUserCog}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetUser;
