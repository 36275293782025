import DataTable from "react-data-table-component";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TableStyle1 from "./UTILS/TableStyle";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faClose,
  faDownload,
  faPlus,
  faPrint,
  faT,
  faTimesCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import axiosInstance from "./UTILS/axios";
import { Modal } from "react-bootstrap";
import exportToExcel from "./UTILS/exportExcel";
import { printContent } from "./UTILS/printUtil";

const ViewAllTickets = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [requestPending, setRequestPending] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [toggleSelected, setToggleSelected] = useState(false);
  const [selected, setSelected] = useState([]);
  const [formData, setFormData] = useState({
    status: "",
    date1: "",
    category: "",
    date2: "",
    userId: "",
  });
  const email = localStorage.getItem("email");
  const username = localStorage.getItem("name");
  const role = localStorage.getItem("role");
  const isAdmin = localStorage.getItem("GWEL_helpdesk_user_isAdmin");
  const [tickets, setTickets] = useState([]);

  const [filteredTickets, setFilteredTickets] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const printRefs = useRef({});

  const handlePrint = (title, ticketId) => {
    const ref = printRefs.current[ticketId];
    if (ref) {
      const clonedContent = ref.cloneNode(true);

      // Optional: Remove any elements that should not be printed
      clonedContent
        .querySelectorAll('button, input[type="checkbox"]')
        .forEach((el) => el.remove());
      printContent(title, clonedContent);
    }
  };

  const stripHTMLTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const handleExport = () => {
    const transformedData = tickets.map((item) => {
      const timeRaised = moment.unix(item.time);
      const dateResolved = item.dateResolved
        ? moment.unix(item.dateResolved)
        : null;

      // Calculate duration in seconds
      const durationInSeconds = dateResolved
        ? dateResolved.diff(timeRaised, "seconds")
        : null;

      // Convert seconds to days, hours, minutes, and seconds
      const duration = durationInSeconds
        ? moment.duration(durationInSeconds, "seconds")
        : null;

      let durationTaken = "N/A";
      if (duration) {
        if (duration.asMonths() >= 1) {
          durationTaken = `${Math.floor(duration.asMonths())} months`;
        } else if (duration.asWeeks() >= 1) {
          durationTaken = `${Math.floor(duration.asWeeks())} weeks`;
        } else if (duration.asDays() >= 1) {
          durationTaken = `${Math.floor(duration.asDays())} days`;
        } else if (duration.asHours() >= 1) {
          durationTaken = `${Math.floor(duration.asHours())} hours`;
        } else if (duration.asMinutes() >= 1) {
          durationTaken = `${Math.floor(duration.asMinutes())} minutes`;
        } else {
          durationTaken = `${duration.seconds()} seconds`;
        }
      }

      return {
        ID: `#${item.id}`,
        SUBJECT: stripHTMLTags(item.subject),
        STATUS: item.status,
        URGENCY: item.urgency,
        RAISEDBY: item.user,
        TIMERAISED: timeRaised.format("MMMM Do YYYY, h:mm:ss a"),
        DATERESOLVED: dateResolved
          ? dateResolved.format("MMMM Do YYYY, h:mm:ss a")
          : "N/A",
        REQUEST: stripHTMLTags(item.content),
        DURATIONTAKEN: durationTaken,
        RESOLVEDBY: item.resolvedBy,
      };
    });

    exportToExcel(transformedData, `Support tickets${startDate}- ${endDate}`);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchTerm(query);
    const filtered = tickets.filter(
      (ticket) =>
        ticket.subject.toLowerCase().includes(query) ||
        ticket.content.toLowerCase().includes(query) ||
        ticket.id.toLowerCase().includes(query) ||
        ticket.user.toLowerCase().includes(query)
    );
    setFilteredTickets(filtered);
  };

  const deleteTicket = () => {
    axiosInstance
      .post(
        "/tickets/delete",
        { isAdmin: isAdmin, tickets: selected },
        { timeout: 15000 }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setSelected([]);
          fetchTickets();
        }
      })
      .catch((error) => {});
  };

  const markResolved = () => {
    axiosInstance
      .post(
        "/tickets/resolve",
        {
          tickets: selected,
          resolvedBy: username,
        },
        { timeout: 15000 }
      )
      .then((response) => {
        if (response.data.status === "success") {
          setSelected([]);
          fetchTickets();
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const fetchTickets = () => {
    axiosInstance
      .get("/tickets/get", {
        params: {
          userId: email,
          status: "all",
          isAdmin: isAdmin,
          date1: moment(startDate).format("YYYY-MM-DD"),
          date2: moment(endDate).format("YYYY-MM-DD"),
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setTickets(response.data.data);
          setFilteredTickets(response.data.data);
        }
      })
      .catch((error) => {});
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setRequestPending(true);
    axiosInstance
      .get("/tickets/get", {
        params: {
          userId: email,
          status: "all",
          isAdmin: isAdmin,
          date1: moment(startDate).format("YYYY-MM-DD"),
          date2: moment(endDate).format("YYYY-MM-DD"),
        },
      })
      .then((response) => {
        setRequestPending(false);
        if (response.data.status === "success") {
          setTickets(response.data.data);
          setFilteredTickets(response.data.data);
        }
      })
      .catch((error) => {
        setRequestPending(false);
      });
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const handleCheckboxChange = (ticketId) => {
    setSelected((prevSelected) => {
      const alreadySelected = prevSelected.some((item) => item.id === ticketId);

      if (alreadySelected) {
        return prevSelected.filter((item) => item.id !== ticketId);
      } else {
        const ticket = tickets.find((t) => t.id === ticketId);
        return [
          ...prevSelected,
          {
            id: ticket.id,
            senderIdEmail: ticket.email,
          },
        ];
      }
    });
  };

  const filterTickets = (status) => {
    if (status === "all") {
      setFilteredTickets(tickets);
    } else {
      const filtered = tickets.filter((ticket) => ticket.status === status);
      setFilteredTickets(filtered);
    }
  };

  return (
    <>
      <div className="content-container">
        <div className="content-module">
          <div className="d-flex align-items-center mb-3">
            <div className="input-group input-group-round">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">search</i>
                </span>
              </div>
              <input
                type="text"
                className="form-control filter-list-input"
                placeholder="Search tickets"
                aria-label="Search tickets"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>

            {role !== "Administrator" ? (
              <Link to={"/tickets/create"} className="btn btn-round ml-3">
                <FontAwesomeIcon icon={faPlus} />
              </Link>
            ) : (
              <button
                className="btn btn-round shadow ml-auto"
                onClick={handleExport}
              >
                <span className="material-icons">download</span>
              </button>
            )}
          </div>

          <div className={`${selected.length < 1 && "d-none"}`}>
            <div className="d-flex ml-auto">
              <button className="btn" onClick={() => deleteTicket()}>
                <FontAwesomeIcon icon={faTrash} />
                Delete
              </button>
              <button className="btn" onClick={() => markResolved()}>
                <FontAwesomeIcon icon={faCheckSquare} />
                Mark resolved
              </button>
            </div>
          </div>
          <div
            className="content-list-body"
            style={{ overflow: "auto", height: "calc(100vh - 150px)" }}
          >
            <div className="card-list">
              <form onSubmit={handleFormSubmit}>
                <div className="card-list-head">
                  <div className="d-flex align-items-center">
                    <label>From:</label>
                    <ReactDatePicker
                      showIcon
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="yyyy/MM/dd"
                      placeholderText="Select date"
                      name="date1"
                      className="form-control"
                    />
                  </div>

                  <div className="d-flex align-items-center">
                    <label>To:</label>
                    <ReactDatePicker
                      showIcon
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="yyyy/MM/dd"
                      placeholderText="Select date"
                      name="date2"
                      className="form-control"
                    />
                  </div>

                  {!requestPending && (
                    <button className="btn btn-round" type="submit">
                      <span className="material-icons">search</span>
                    </button>
                  )}
                </div>
              </form>
              {tickets.length > 0 ? (
                <div className="card-list-body mt-4">
                  {filteredTickets.map((ticket) => (
                    <div
                      key={ticket.id}
                      className="card card-task rounded-0"
                      ref={(el) => (printRefs.current[ticket.id] = el)}
                    >
                      <div className="px-1 d-flex align-items-center">
                        <label>
                          <input
                            type="checkbox"
                            checked={selected.some(
                              (item) => item.id === ticket.id
                            )}
                            onChange={() => handleCheckboxChange(ticket.id)}
                          />
                          #{ticket.id}
                        </label>

                        <button
                          onClick={() => handlePrint(ticket.subject, ticket.id)}
                          className="btn ml-auto"
                          type="button"
                        >
                          <FontAwesomeIcon icon={faPrint} />
                        </button>
                      </div>
                      <div className="card-body py-2 d-flex flex-column">
                        <div className="card-title d-flex w-100">
                          <span className="row-title">{ticket.subject}</span>
                          <span className="text-small ml-auto d-block">
                            {moment(ticket.time * 1000).fromNow()}
                          </span>
                        </div>
                        <div
                          className="mb-1"
                          dangerouslySetInnerHTML={{ __html: ticket.content }}
                        />
                      </div>

                      {ticket.attachments && ticket.attachments.length > 0 && (
                        <div>
                          {ticket.attachments.map((attachment, index) => (
                            <img
                              className="mb-3"
                              key={index}
                              alt={attachment}
                              src={`https://ict.greenwellsenergies.com/helpdesk/api/tickets/uploads/${attachment}`}
                              style={{ maxHeight: "200px" }}
                            />
                          ))}
                        </div>
                      )}

                      <hr />
                      <div className="d-flex">
                        {ticket.status.charAt(0).toUpperCase() +
                          ticket.status.slice(1)}

                        <span className="h6 ml-auto">{ticket.user}</span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center">
                  <p>No tickets found for the selected period.</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="sidebar collapse" id="sidebar-collapse">
          <div className="sidebar-content">
            <div className="content-end-sidebar text-small">
              <div className="content-end-sidebar-top">
                <ul
                  className="nav nav-tabs flex-column px-2 text-uppercase"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#all"
                      role="tab"
                      onClick={() => filterTickets("all")}
                    >
                      All tickets
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#pending"
                      role="tab"
                      onClick={() => filterTickets("pending")}
                    >
                      Pending
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#resolved"
                      role="tab"
                      onClick={() => filterTickets("resolved")}
                    >
                      Resolved
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAllTickets;
