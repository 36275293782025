import { useEffect, useState } from "react";
import axiosInstance from "./UTILS/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import { Button, ButtonToolbar } from "react-bootstrap";

const KnowledgeBase = () => {
  const [data, setData] = useState([]);
  const [requestPending, setRequestPending] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({ id: "", title: "", content: "" });

  // Fetch info from the server
  const fetchData = () => {
    axiosInstance
      .get("/info/get", { timeout: 5000 })
      .then((response) => {
        if (response.data) {
          setData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching links:", error);
      });
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setRequestPending(true);

    axiosInstance
      .post("/info/set", formData, { timeout: 10000 })
      .then((response) => {
        setRequestPending(false);
        if (response.data.status === "success") {
          fetchData();
          setFormData({ id: "", title: "", content: "" });
        }
      })
      .catch((error) => {
        setRequestPending(false);
        console.error("Error submitting form:", error);
      });
  };

  // Handle search input change
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter links based on search term
  const filteredData = data.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle delete link
  const handleDelete = (id) => {
    axiosInstance
      .post("/info/delete", { id: id }, { timeout: 10000 })
      .then((response) => {
        if (response.data.status === "success") {
          fetchData();
        }
      })
      .catch((error) => {
        console.error("Error deleting link:", error);
      });
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="content-container">
      <div className="content-module">
        <h2>Manage Info Links</h2>
        <form className="content-container" onSubmit={handleFormSubmit}>
          <div
            className="content-module"
            style={{ maxHeight: "calc(100vh - 50px)", overflow: "auto" }}
          >
            <input
              type="hidden"
              name="id"
              value={formData.id}
              onChange={handleInputChange}
            />
            <div className="form-group mb-4">
              <label className="form-label">Title:</label>
              <input
                type="text"
                className="form-control"
                name="title"
                placeholder="Enter name or title"
                value={formData.title}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group mb-2">
              <label className="form-label">Content:</label>
              <ReactQuill
                value={formData.content}
                onChange={(value) =>
                  setFormData((prevState) => ({ ...prevState, content: value }))
                }
              />
            </div>

            <div className="d-flex">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setFormData({ id: "", title: "", content: "" })}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-primary d-block ml-auto"
                disabled={requestPending}
              >
                {requestPending ? "Please wait..." : "Submit"}
                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="sidebar collapse" id="sidebar-collapse">
        <div
          className="sidebar-content"
          style={{ overflow: "auto", height: "calc(100vh - 50px)" }}
        >
          <div className="content-end-sidebar text-small">
            <div className="content-end-sidebar-top">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>

            {/* Display filtered data */}
            <ul
              className="nav nav-tabs flex-column mx-3 px-2 text-uppercase"
              role="tablist"
            >
              {filteredData.map((item) => (
                <li className="nav-item d-flex" key={item.id}>
                  <a
                    className="nav-link w-100"
                    data-toggle="tab"
                    href="#all"
                    role="tab"
                    onClick={() => setFormData(item)}
                  >
                    {item.title}
                  </a>

                  <button
                    className="btn btn-round ml-1"
                    onClick={() => handleDelete(item.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KnowledgeBase;
