const base64Decode = (encodedString) => {
  let decodedData = null;
  let error = null;

  try {
    const decodedString = atob(encodedString);
    decodedData = JSON.parse(decodedString);
  } catch (err) {
    error = "Error decoding or parsing the encoded string";
    console.error("Error decoding or parsing the encoded string:", err);
  }

  return { error, decodedData };
};

export default base64Decode;
