import {
  faArrowRight,
  faClose,
  faTimesCircle,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import axiosInstance from "./UTILS/axios";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";

const CreateTicket = () => {
  const email = localStorage.getItem("email");
  const name = localStorage.getItem("name");
  const [requestPending, setRequestPending] = useState(false);
  const [categories, setCategories] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [content, setContent] = useState("");
  const [formData, setFormData] = useState({
    subject: "",
    category: "",
    cc: "",
    priority: "low",
  });
  const [attachments, setAttachments] = useState([]);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSelectChange = (value, actionMeta) => {
    if (actionMeta.name === "category") {
      // For single select (category)
      setFormData({ ...formData, [actionMeta.name]: value ? value.value : "" });
    } else {
      // For multi-select (CC users)
      const selected = value.map((option) => option.value).join(",");
      setFormData({ ...formData, [actionMeta.name]: selected });
    }
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) => file.type.startsWith("image/"));
    setAttachments([...attachments, ...validFiles]);
    e.target.value = "";
  };

  const handleDeleteAttachment = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setRequestPending(true);

    const formDataToSend = new FormData();
    formDataToSend.append("subject", formData.subject);
    formDataToSend.append("category", formData.category);
    formDataToSend.append("cc", formData.cc);
    formDataToSend.append("priority", formData.priority);
    formDataToSend.append("content", content);
    formDataToSend.append("postedByEmail", email);
    formDataToSend.append("postedByName", name);
    attachments.forEach((file, index) => {
      formDataToSend.append(`file${index}`, file);
    });

    axiosInstance
      .post("/tickets/create", formDataToSend, { timeout: 60000 })
      .then((response) => {
        setRequestPending(false);
        if (response.data.status === "success") {
          navigate("/tickets");
        }
      })
      .catch((error) => {
        setRequestPending(false);
      });
  };

  useEffect(() => {
    axiosInstance
      .get("/users/get")
      .then((response) => {
        const options = [];
        if (response.data) {
          response.data.forEach((item) => {
            options.push({ value: item.email, label: item.username });
          });
          setContacts(options);
        }
      })
      .catch((error) => {});

    axiosInstance
      .get("/tickets/categories/get")
      .then((response) => {
        const options = [];
        if (response.data) {
          response.data.forEach((item) => {
            options.push({ value: item.name, label: item.name });
          });
          setCategories(options);
        }
      })
      .catch((error) => {});
  }, []);

  return (
    <>
      <form className="content-container" onSubmit={handleSubmit}>
        <div
          className="content-module"
          style={{ maxHeight: "calc(100vh - 50px)", overflow: "auto" }}
        >
          <div className="form-group mb-1">
            <label className="form-label">Subject:</label>

            <input
              type="text"
              className="form-control"
              name="subject"
              placeholder="Enter subject of your request"
              value={formData.subject}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group mb-1">
            <label className="form-label">Category:</label>

            <ReactSelect
              placeholder={"Select category"}
              onChange={onSelectChange}
              name="category"
              isMulti={false}
              options={categories}
              className="basic-multi-select"
              classNamePrefix="select"
              required
            />
          </div>

          <div className="form-group mb-1">
            <label className="form-label">CC:</label>

            <ReactSelect
              placeholder={"CC users"}
              onChange={onSelectChange}
              name="cc"
              isMulti
              options={contacts}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>

          <div className="form-group mb-4">
            <label className="form-label">Priority:</label>
            <select
              className="form-control"
              name="priority"
              value={formData.priority}
              onChange={handleInputChange}
            >
              <option value={"low"}>Low</option>
              <option value={"high"}>High</option>
              <option value={"urgent"}>Urgent</option>
            </select>
          </div>

          <div className="form-group mb-2">
            <label className="form-label">Short description:</label>
            <ReactQuill value={content} onChange={setContent} />
          </div>

          {requestPending ? (
            <button
              type="submit"
              className="btn btn-primary d-block ml-auto"
              disabled
            >
              Please wait...
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          ) : (
            <button type="submit" className="btn btn-primary d-block ml-auto">
              Submit
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          )}
        </div>
        <div className="sidebar collapse" id="sidebar-collapse">
          <div className="sidebar-content">
            <div className="content-end-sidebar text-small mt-3">
              <input
                type="file"
                className="form-control d-none"
                id="attachments"
                name="attachments"
                multiple
                accept="image/*"
                onChange={handleFileChange}
              />
              <label
                htmlFor="attachments"
                className="btn btn-white bg-white w-100 border py-2 "
              >
                <span
                  className="material-icons text-dark"
                  style={{ verticalAlign: "bottom" }}
                >
                  upload
                </span>
                {attachments.length === 0
                  ? "Attach files"
                  : `${attachments.length} file(s) selected`}
              </label>

              <ul>
                {attachments.length > 0 &&
                  attachments.map((file, index) => (
                    <li
                      key={index}
                      className="col mb-2 d-flex align-items-center w-100"
                    >
                      <img
                        src={URL.createObjectURL(file)}
                        alt="preview"
                        className="img-thumbnail"
                        style={{ height: "50px" }}
                      />

                      <button
                        className="btn ml-auto"
                        role="button"
                        onClick={() => handleDeleteAttachment(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateTicket;
