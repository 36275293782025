import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import base64Decode from "./UTILS/Base64Decoder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "./UTILS/axios";
import { printContent } from "./UTILS/printUtil";

const Info = () => {
  const location = useLocation();
  const [data, setData] = useState(null);
  const printRef = useRef();
  const param = useParams();

  const handlePrint = () => {
    printContent(data.title, printRef);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const param = queryParams.get("id");

    axiosInstance
      .get("/info/fetch", { params: { id: param } })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {});
  }, [location]);
  return (
    <>
      <div className="p-2">
        {data && (
          <div className="card rounded-0  shadow-none mt-3">
            <div className="card-header d-flex">
              <h4 className="mb-0">{data.title}</h4>

              <button
                onClick={handlePrint}
                className="btn ml-auto"
                type="button"
              >
                <FontAwesomeIcon icon={faPrint} />
              </button>
            </div>
            <div
              ref={printRef}
              className="mb-1"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Info;
