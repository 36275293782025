// utils/printUtils.js
export const printContent = (title, contentRef) => {
  const printWindow = window.open("", "", "height=600,width=800");

  // Copy styles from the parent document to the print window
  const styles = Array.from(document.styleSheets)
    .map((styleSheet) => {
      try {
        return Array.from(styleSheet.cssRules)
          .map((rule) => rule.cssText)
          .join("\n");
      } catch (e) {
        return ""; // Some stylesheets may not be accessible due to cross-origin restrictions
      }
    })
    .join("\n");

  printWindow.document.write(`
      <html>
        <head>
          <title>${title}</title>
          <style>${styles}</style>
        </head>
        <body>
          ${contentRef.innerHTML ?? contentRef.current.innerHTML}
        </body>
      </html>
    `);

  printWindow.document.close();
  printWindow.focus();
  printWindow.print();
  printWindow.close();
};
