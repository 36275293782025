import { faCogs, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "./UTILS/axios";
import DataTable from "react-data-table-component";
import TableStyle1 from "./UTILS/TableStyle";

const Users = () => {
  const [data, setData] = useState([]);
  const [requestPending, setRequestPending] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [departments, setDepartments] = useState([]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchTerm(query);
    const filtered = data.filter((user) =>
      user.username.toLowerCase().includes(query)
    );
    setFilteredUsers(filtered);
  };

  const filterDepartment = (department) => {
    if (department === "all") {
      setFilteredUsers(data);
    } else {
      const filtered = data.filter((item) => item.department === department);
      setFilteredUsers(filtered);
    }
  };

  const deleteUser = (id) => {
    const data = [];
    data.push(id);
    axiosInstance
      .post("/users/delete", { id: data }, { timeout: 10000 })
      .then((response) => {
        if (response.data.status === "success") {
          fetchUsers();
        }
      });
  };

  const fetchUsers = () => {
    axiosInstance
      .get("/users/get")
      .then((response) => {
        setData(response.data);
        setFilteredUsers(response.data);
        const uniqueDepartments = [
          ...new Set(response.data.map((item) => item.department)),
        ];

        setDepartments(uniqueDepartments);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <div className="content-container">
        <div className="content-module">
          <div className="d-flex align-items-center mb-3">
            <div className="input-group input-group-round">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="material-icons">search</i>
                </span>
              </div>
              <input
                type="text"
                className="form-control filter-list-input"
                placeholder="Search tickets"
                aria-label="Search tickets"
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>

            <Link to={"/users/set"} className="btn btn-round ml-3">
              <FontAwesomeIcon icon={faPlus} />
            </Link>
          </div>

          <div
            className="content-list-body"
            style={{ overflow: "auto", height: "calc(100vh - 150px)" }}
          >
            {data.length > 0 ? (
              <ol className="list-group list-group-activity filter-list-1637505476004">
                {filteredUsers.map((user, index) => (
                  <li className="list-group-item mb-2" key={index}>
                    <div className="media align-items-center">
                      <ul className="avatars">
                        <li>
                          <img
                            alt={user.username}
                            src="assets/avatars/avatar1.png"
                            className="avatar filter-by-alt"
                            data-filter-by="alt"
                            style={{ maxWidth: "50px" }}
                          />
                        </li>
                      </ul>
                      <div className="media-body">
                        <div>
                          <span className="row-title" data-filter-by="text">
                            {user.username}
                          </span>
                          <a href="#" data-filter-by="text">
                            {user.email}
                          </a>
                        </div>
                      </div>

                      <div className="dropdown ml-auto">
                        <button
                          className="btn-options"
                          type="button"
                          id="task-dropdown-button-1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="material-icons">more_vert</i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                          <Link
                            className="dropdown-item"
                            to={`/users/set?user=${btoa(JSON.stringify(user))}`}
                          >
                            <FontAwesomeIcon
                              className="icon-light"
                              icon={faCogs}
                            />
                          </Link>
                          <div className="dropdown-divider"></div>
                          <a
                            className="dropdown-item text-danger"
                            href="#"
                            onClick={() => deleteUser(user.userid)}
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ol>
            ) : (
              <div className="text-center">
                <p>No users found.</p>
              </div>
            )}
          </div>
        </div>
        <div className="sidebar collapse" id="sidebar-collapse">
          <div className="sidebar-content">
            <div className="content-end-sidebar text-small">
              <div className="content-end-sidebar-top">
                <ul
                  className="nav nav-tabs flex-column px-2 text-uppercase"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      onClick={() => filterDepartment("all")}
                      className="nav-link active"
                      data-toggle="tab"
                      href="#all"
                      role="tab"
                    >
                      All users
                    </a>
                  </li>

                  {departments.map((department, index) => (
                    <li className="nav-item" key={index}>
                      <a
                        onClick={() => filterDepartment(department)}
                        className="nav-link"
                        data-toggle="tab"
                        href="#all"
                        role="tab"
                      >
                        {department}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
