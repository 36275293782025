import { useEffect, useState } from "react";
import axiosInstance from "./UTILS/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import TableStyle1 from "./UTILS/TableStyle";
import { Link } from "react-router-dom";

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [toggleSelected, setToggleSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [requestPending, setRequestPending] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({ name: "" });
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setRequestPending(true);
    axiosInstance
      .post("/tickets/categories/set", formData, { timeout: 10000 })
      .then((response) => {
        setRequestPending(false);
        if (response.data.status === "success") {
          fetchData();
          setFormData({ ...formData, name: "" });
        }
      })
      .catch((error) => {
        setRequestPending(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axiosInstance
      .get("/tickets/categories/get", { timeout: 5000 })
      .then((response) => {
        if (response.data) {
          setCategories(response.data);
        }
      })
      .catch((error) => {});
  };

  const deleteSelected = () => {
    axiosInstance
      .post(
        "/tickets/categories/delete",
        { id: selectedItems },
        { timeout: 10000 }
      )
      .then((response) => {
        if (response.data.status === "success") {
          fetchData();
          setSelectedItems([]);
        }
      })
      .catch((error) => {});
  };

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(id)
        ? prevSelectedItems.filter((item) => item !== id)
        : [...prevSelectedItems, id]
    );
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="content-container">
        <div className="content-module">
          <div style={{ overflow: "auto", height: "calc(100vh - 50px)" }}>
            <input
              type="text"
              className="form-control mb-3"
              placeholder="Search categories..."
              value={searchTerm}
              onChange={handleSearch}
            />

            <div className="d-flex justify-content-end mb-1">
              <button
                className={`btn btn-danger ${
                  selectedItems.length < 1 && "d-none"
                }`}
                onClick={deleteSelected}
              >
                <FontAwesomeIcon icon={faTrash} /> Delete Selected
              </button>
            </div>
            {filteredCategories.length > 0 ? (
              <ol className="list-group list-group-activity filter-list-1637505476004">
                {filteredCategories.map((item, index) => (
                  <li className="list-group-item mb-2" key={index}>
                    <div className="media align-items-center">
                      <div className="media-body">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            checked={selectedItems.includes(item.id)}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                          <span
                            className="row-title ml-2"
                            data-filter-by="text"
                          >
                            {item.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ol>
            ) : (
              <div className="text-center">
                <p>No categories found.</p>
              </div>
            )}
          </div>
        </div>
        <div className="sidebar collapse" id="sidebar-collapse">
          <div className="sidebar-content">
            <div className="content-end-sidebar text-small">
              <div className="content-end-sidebar-top">
                <span className="row-title">Add category</span>

                <form className="mt-2" onSubmit={handleFormSubmit}>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    onChange={handleOnChange}
                    placeholder="Enter name"
                    value={formData.name}
                  />

                  <div className="d-flex justify-content-end mt-3">
                    {requestPending ? (
                      <button
                        type="submit"
                        className="btn btn-primary d-block ml-auto"
                        disabled
                      >
                        Please wait...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary d-block ml-auto"
                      >
                        Save
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
